import { FiltersProps } from '@/stores/filters';

const API_CONFIG = {
  ROOT_URL: process.env.API_URL,
  PAGE_SIZE: Number(process.env.PAGE_SIZE) || 20,
};

export const API_ENDPOINTS = {
  CERTIFICATIONS_FOR_TASK: (
    taskId: string | string[],
    filters: FiltersProps,
    page = 1
  ): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (Object.keys(filters).length > 0) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `tasks/${taskId}/certifications?${params}`;
  },
  MERGE_REPORTS: () => `/reports/merge`,
  CERTIFICATIONS_FOR_EMAIL: (
    inspectionId: string | string[],
    filters: FiltersProps,
    page = 1
  ): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (Object.keys(filters).length > 0) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `emails/inspection/${inspectionId}/certifications?${params}`;
  },
  CERTIFICATIONS_FOR_WORKFLOW: (
    workflowId: string | string[],
    filters: FiltersProps,
    page = 1
  ): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (Object.keys(filters).length > 0) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `workflows/inspection/${workflowId}/certifications?${params}`;
  },
  CERTIFICATIONS_FOR_CLAIMS: (
    claimId: string | string[],
    filters: FiltersProps,
    page = 1
  ): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (Object.keys(filters).length > 0) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `/claims/inspection/${claimId}/certifications?${params}`;
  },
  USER_CERTIFICATIONS: (filters: FiltersProps, page = 1): string => {
    let params = `page=${page}&page_size=${API_CONFIG.PAGE_SIZE}`;

    if (filters) {
      const [filterKey] = Object.keys(filters);
      params = `${filterKey}=True&${params}`;
    }

    return `certifications/list?${params}`;
  },
  ARCHIVE_TASKS: () => `/tasks/archive`,
  CERTIFICATION_FAVORITE: (identifier: string) =>
    `/certifications/${identifier}/favorite`,
  DOWNLOAD_ASSET: (identifier: string): string =>
    `certifications/download_file_for_user/${identifier}`,
  DOWNLOAD: (identifier: string): string =>
    `certifications/download/${identifier}`,
  DOWNLOAD_TASK: (identifier: string): string => `tasks/download/${identifier}`,
  DOWNLOAD_EMAIL: (identifier: string): string =>
    `emails/inspection/${identifier}/download`,
  DOWNLOAD_EMAIL_TASK: (identifier: string): string =>
    `emails/download/${identifier}`,
  DOWNLOAD_WORKFLOW: (identifier: string): string =>
    `workflows/download/${identifier}`,
  DOWNLOAD_INSPECTION: (identifier: string): string =>
    `/inspections/download/${identifier}`,
  LINK_DETAILS: (id: string | string[]): string =>
    `/certifications/link_details/${id}`,
  CERTIFICATION_DETAILS: (identifier?: string): string =>
    `certifications/details/${identifier}`,
  USER_PROFILE: (): string => 'auth/me',
  SUBSCRIPTION_INFORMATION: (): string => 'auth/subscription_information',
  TASK_DETAILS: (identifier?: string): string => `tasks/${identifier}`,
  TASK_DETAILS_WITH_USER_DATA: (identifier?: string): string =>
    `tasks/get_task_with_users_data/${identifier}`,
  CLAIM_DETAILS: (identifier?: number): string => `claims/read/${identifier}`,
  WORKFLOWS_DETAILS_WITH_USER_DATA: (identifier: string): string =>
    `workflows/get_workflow_with_users_data/${identifier}`,
  CLAIM_COMPLETE_READ: (identifier: string): string =>
    `claims/complete_read/${identifier}`,
  TEMPLATES_FOR_SELECT: (categoryId?: number): string =>
    `templates/list_for_select${
      categoryId ? `?company_category_id=${categoryId}` : ``
    }`,
  WORKFLOWS_FOR_SELECT: (identifier?: string): string =>
    `/workflows/list_for_select${identifier ? `?folder_id=${identifier}` : ``}`,
  FOLDERS: (): string => `/folders/list_for_select`,
  TASK_FOLDERS: (): string => `/tasks/folders`,
  CLAIM_FOLDERS: (): string => `/claims/folders`,
  TASK_FOLDER: (identifier?: string): string => `/folders/${identifier}`,
  TASK_FOLDER_DELETE: (identifier: string | null): string =>
    `/folders/${identifier}`,
  TASK_FOLDER_SAVE: (identifier: string): string =>
    `/tasks/folder/${identifier}`,
  CLAIM_FOLDER_SAVE: (identifier: number): string =>
    `/claims/folder/update/${identifier}`,
  TASK_FOLDER_CREATE: (): string => '/tasks/folder/create',
  CLAIM_FOLDER_CREATE: (): string => '/claims/folder/create/',
  USERS: (id?: number): string => `/users/${id || ''}`,
  USERS_FOR_FOLDER: (): string => '/folders/',
  USERS_FOR_TASK: () => '/tasks/users_for_task',
  USER_DETAILS: (identifier?: number): string => `users/${identifier}`,
  TOGGLE_REQUIRE_METADATA: (): string => '/auth/mandatory_metadata',
  TASK_FORM_DETAILS: (identifier?: string): string =>
    `/tasks/${identifier}/form`,
  REPORTS_FOR_SELECT_LIST: (): string => '/reports/list_for_select',
  ALL_REPORTS: (): string => '/certifications/reports',
  REPORTS_GENERATE: (): string => '/reports/generate',
  CREATE_CHILD_WORKFLOW: (inspectionId: number): string =>
    `/workflows/child/create/${inspectionId}`,
  CREATE_CHILDREN_WORKFLOW: (inspectionId: number): string =>
    `/workflows/children/create/${inspectionId}`,
  WORKFLOW_FOLDERS: (): string => `/workflows/folders`,
  WORKFLOW_FOLDER_WITH_USERS_DATA: (identifier?: number): string =>
    `/folders/get_folder_with_users_data/${identifier}`,
  WORKFLOW_FOLDER: (identifier?: number): string => `/folders/${identifier}`,
  CLAIM_FOLDER: (identifier?: number): string => `/folders/${identifier}`,
  WORKFLOW_FOLDER_DELETE: (identifier: number | null): string =>
    `/folders/${identifier}`,
  CLAIM_FOLDER_DELETE: (identifier: number | null): string =>
    `/folders/${identifier}`,
  WORKFLOW_FOLDER_SAVE: (identifier: number): string =>
    `/workflows/folder/${identifier}`,
  WORKFLOW_FOLDER_CREATE: (): string => '/workflows/folder/create',
  WORKFLOW: (identifier?: number): string => `/workflows/${identifier}`,
  WORKFLOW_ARCHIVE: (identifier?: number): string =>
    `/tasks/archive/${identifier}`,
  WORKFLOW_DELETE: (identifier: number): string => `/workflows/${identifier}`,
  WORKFLOW_SAVE: (identifier: number): string => `/workflows/${identifier}`,
  WORKFLOW_CREATE: (): string => '/workflows/create',
  WORKFLOWS: (): string => '/workflows',
  CLAIMS: (): string => '/claims',
  CLAIM_INSPECTIONS: (identifier: number): string =>
    `/claims/${identifier}/inspections`,
  INSPECTIONS: (identifier?: string): string =>
    `/workflows/${identifier}/inspections`,
  INSPECTIONS_LIST: (): string => `/inspections/list`,
  INSPECTION_SAVE: (identifier: number): string =>
    `/workflows/inspection/${identifier}`,
  INSPECTION_DELETE: (identifier: number): string =>
    `/inspections/${identifier}`,
  INSPECTION_FORM: (identifier: string): string =>
    `/inspections/form/${identifier}`,
  INSPECTIONS_FOR_SELECT: (identifier: string): string =>
    `/inspections/list_for_select?task_id=${identifier}`,
  TEMPLATE: (identifier?: number): string => `/templates/${identifier}`,
  EMAIL: (identifier?: number): string =>
    `/emails/get_emails_with_user_data/${identifier}`,
  EMAIL_TASKS: (): string => `/emails/`,
  EMAIL_DETAILS: (): string => `/emails/`,
  EMAILS_FOLDERS: (): string => `/emails/folders`,
  EMAIL_TASK_ARCHIVE: (identifier: number): string =>
    `/tasks/archive/${identifier}`,
  EMAIL_TASK_DELETE: (identifier?: number): string =>
    `/emails/delete/${identifier}`,
  CLIENTS: () => `/tasks/clients_for_task`,
  CREATE_INSPECTION: () => `/inspections/create`,
  SUBMIT_FORM_TOKEN: (inspectionId: string) =>
    `/tasks/submit_form_token/${inspectionId}`,
  SUBMIT_FORM: (inspectionId: string) => `/tasks/submit_form/${inspectionId}`,
};

export default API_CONFIG;
