import clsx from 'clsx';

const IntiLogo = ({
  className,
  filled = false,
}: {
  className?: string;
  filled?: boolean;
}) => {
  return (
    <svg
      width='246'
      height='93'
      viewBox='0 0 246 93'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={clsx(className, !filled ? 'fill-white' : 'fill-primary-500')}
    >
      <g clip-path='url(#clip0_458_902)'>
        <path d='M41.339 18.0549H35.5445V23.8891H41.339V18.0549Z' />
        <path d='M24.5857 18.0548H18.7913V23.889H24.5857V18.0548Z' />
        <path d='M33.0936 18.0548H27.2992V23.889H33.0936V18.0548Z' />
        <path d='M66.4938 18.0548H60.6994V23.889H66.4938V18.0548Z' />
        <path d='M49.7405 18.0546H43.946V23.8888H49.7405V18.0546Z' />
        <path d='M58.2485 18.0546H52.4541V23.8888H58.2485V18.0546Z' />
        <path d='M74.9329 18.0548H69.1384V23.889H74.9329V18.0548Z' />
        <path d='M41.3385 26.6195H35.5441V32.4538H41.3385V26.6195Z' />
        <path d='M24.5851 26.6193H18.7907V32.4536H24.5851V26.6193Z' />
        <path d='M33.0932 26.6193H27.2987V32.4536H33.0932V26.6193Z' />
        <path d='M66.4934 26.6193H60.699V32.4536H66.4934V26.6193Z' />
        <path d='M49.74 26.6193H43.9456V32.4535H49.74V26.6193Z' />
        <path d='M58.248 26.6193H52.4536V32.4535H58.248V26.6193Z' />
        <path d='M74.9324 26.6193H69.138V32.4536H74.9324V26.6193Z' />
        <path d='M41.3385 35.1541H35.5441V40.9883H41.3385V35.1541Z' />
        <path d='M24.5851 35.154H18.7907V40.9882H24.5851V35.154Z' />
        <path d='M33.0932 35.154H27.2987V40.9882H33.0932V35.154Z' />
        <path d='M66.4934 35.154H60.699V40.9882H66.4934V35.154Z' />
        <path d='M49.74 35.1539H43.9456V40.9881H49.74V35.1539Z' />
        <path d='M58.248 35.1539H52.4536V40.9881H58.248V35.1539Z' />
        <path d='M74.9324 35.154H69.138V40.9882H74.9324V35.154Z' />
        <path d='M41.5648 43.7188H35.7704V49.553H41.5648V43.7188Z' />
        <path d='M24.8114 43.7186H19.017V49.5528H24.8114V43.7186Z' />
        <path d='M33.3194 43.7186H27.525V49.5528H33.3194V43.7186Z' />
        <path d='M66.7197 43.7186H60.9253V49.5528H66.7197V43.7186Z' />
        <path d='M49.9663 43.7185H44.1718V49.5527H49.9663V43.7185Z' />
        <path d='M58.4743 43.7185H52.6799V49.5527H58.4743V43.7185Z' />
        <path d='M75.1587 43.7186H69.3642V49.5528H75.1587V43.7186Z' />
        <path d='M41.3386 52.0149H35.5441V57.8491H41.3386V52.0149Z' />
        <path d='M24.5852 52.0147H18.7907V57.8489H24.5852V52.0147Z' />
        <path d='M33.0932 52.0147H27.2988V57.8489H33.0932V52.0147Z' />
        <path d='M66.4935 52.0147H60.699V57.8489H66.4935V52.0147Z' />
        <path d='M49.7401 52.0146H43.9456V57.8488H49.7401V52.0146Z' />
        <path d='M58.2481 52.0146H52.4537V57.8488H58.2481V52.0146Z' />
        <path d='M74.9324 52.0147H69.138V57.8489H74.9324V52.0147Z' />
        <path d='M41.3381 60.5795H35.5437V66.4137H41.3381V60.5795Z' />
        <path d='M24.5847 60.5794H18.7903V66.4136H24.5847V60.5794Z' />
        <path d='M33.0928 60.5794H27.2983V66.4136H33.0928V60.5794Z' />
        <path d='M66.493 60.5794H60.6986V66.4136H66.493V60.5794Z' />
        <path d='M49.7396 60.5792H43.9452V66.4134H49.7396V60.5792Z' />
        <path d='M58.2477 60.5792H52.4532V66.4134H58.2477V60.5792Z' />
        <path d='M74.932 60.5794H69.1376V66.4136H74.932V60.5794Z' />
        <path d='M41.3381 69.1141H35.5437V74.9483H41.3381V69.1141Z' />
        <path d='M24.5847 69.114H18.7903V74.9482H24.5847V69.114Z' />
        <path d='M33.0928 69.114H27.2983V74.9482H33.0928V69.114Z' />
        <path d='M66.493 69.114H60.6986V74.9482H66.493V69.114Z' />
        <path d='M49.7396 69.1138H43.9452V74.9481H49.7396V69.1138Z' />
        <path d='M58.2477 69.1138H52.4532V74.9481H58.2477V69.1138Z' />
        <path d='M74.932 69.114H69.1376V74.9482H74.932V69.114Z' />
        <path d='M46.9796 0.0727844C21.5757 0.0727844 0.974487 20.8594 0.974487 46.5013C0.974487 72.1431 21.5757 92.9297 46.9796 92.9297C72.3834 92.9297 92.9745 72.1431 92.9745 46.5013C92.9745 20.8594 72.3834 0.0727844 46.9796 0.0727844ZM46.9796 3.11699C70.737 3.11699 89.9865 22.5409 89.9865 46.5013C89.9865 70.4617 70.737 89.8856 46.9796 89.8856C23.2221 89.8856 3.96248 70.4617 3.96248 46.5013C3.96248 22.5409 23.2221 3.11699 46.9796 3.11699Z' />
        <path d='M107.023 26.7287L127.035 26.7787L127.133 31.4661L120.949 32.5134V60.5391L127.133 61.7359L127.084 66.2739L106.974 66.2241V61.6362L113.158 60.1402L113.207 32.5633L107.023 31.2168L107.023 26.7287Z' />
        <path d='M134.961 31.3758L141.159 33.0684L141.09 60.0084L134.339 61.6361V66.2739L152.306 66.2241L152.355 61.5365L146.707 59.8909V35.306L166.28 66.3238H174.217L174.022 32.5633L179.524 31.3166L179.622 26.7287L162.19 26.7786L162.239 31.8651L167.985 32.9123L167.887 53.7072L150.504 26.6289L134.972 26.7287L134.961 31.3758Z' />
        <path d='M189.652 43.3846L191.454 32.5134L198.319 31.9649L198.076 60.19L190.577 61.6861L190.821 66.2739L213.754 66.3736L213.657 61.6362L206.451 60.0405L206.548 31.9649L213.219 32.7129L214.485 43.3347H220.084L219.792 26.7287L184.686 26.6789V43.4843L189.652 43.3846Z' />
        <path d='M231.709 32.6806L225.409 31.4111L225.477 26.7919L245.481 26.7214V31.9049L239.387 32.5396L239.353 60.1144L245.584 61.7364L245.447 66.3557H225.34L225.409 61.5249L231.709 60.0791V32.6806Z' />
      </g>
      <defs>
        <clipPath id='clip0_458_902'>
          <rect
            width='244.61'
            height='92.857'
            transform='translate(0.974487 0.0727844)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IntiLogo;
