/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import useTranslation from 'next-translate/useTranslation';
import { denormalize } from 'normalizr';
import { useEffect, useState } from 'react';

import { useWebInspectionStorage } from '@/stores/webInspection';
import { useWebInspectionDocs } from '@/stores/webInspectionDocs';
import { formSchema } from '@/utils/forms';

const useWebInspection = (stepIndex: number) => {
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const {
    inspection,
    setCurrentStepSection,
    setCurrentStep,
    currentStep,
    normalizedForm,
    stepsState,
    draftSchema,
    form,
    resetStore,
  } = useWebInspectionStorage();

  const { t } = useTranslation('common');

  const { resetDocs } = useWebInspectionDocs();

  const currentSection = stepsState?.[stepIndex]?.currentSection;

  useEffect(() => {
    if (stepIndex) {
      setCurrentStepSection(stepIndex, currentSection);
    }
  }, [stepIndex]);

  const totalSteps = stepsState?.length;

  const useGoBack = () => {
    setCurrentStepSection(stepIndex, currentSection - 1);
  };

  const useIsGoBackDisabled = () => totalSteps > 0 && currentSection == 0;

  const checkIsStepFinished = () => {
    const step = inspection?.steps?.[stepIndex];
    if (step?.type === 'form') {
      return currentSection + 1 === form?.schema.length;
    } else {
      return (
        currentSection + 1 ===
          inspection?.steps?.[stepIndex].sections?.length ||
        currentSection + 1 === form?.length
      );
    }
  };

  const useHandleContinue = () => {
    if (currentStep && checkIsStepFinished()) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStepSection(stepIndex, currentSection + 1);
    }
  };

  const getQuestionComponents = (currentSection: any) => {
    const questionComponents: string[] = [];
    currentSection.questions.forEach((questionId: any) => {
      getQuestion(questionId).components.forEach((componentId: any) => {
        const component = getComponent(componentId);
        questionComponents.push(componentId);
        if (component.comment_object) {
          const { comment_object } = component;
          comment_object.forEach((commentObjectId: string) => {
            questionComponents.push(commentObjectId);
          });
        }
      });
    });
    return questionComponents;
  };

  const getFreeQuestionComponents = (currentSection: any) => {
    const freeQuestionComponents: string[] = [];
    currentSection.components.forEach((componentId: any) => {
      const component = getComponent(componentId);
      freeQuestionComponents.push(componentId);
      if (component.comment_object) {
        const { comment_object } = component;
        comment_object.forEach((commentObjectId: string) => {
          freeQuestionComponents.push(commentObjectId);
        });
      }
    });
    return freeQuestionComponents;
  };

  const getSectionComponents = (currentSection: any) => {
    if (!currentSection) return;
    const sectionComponents: string[] = [];

    if (currentSection.questions) {
      sectionComponents.push(...getQuestionComponents(currentSection));
    } else {
      sectionComponents.push(...getFreeQuestionComponents(currentSection));
    }

    return sectionComponents;
  };

  const validateFields = (formMethods: any) => {
    const sections = Object.values(getSections() || {});
    const currentSectionIdx = stepsState?.[stepIndex]?.currentSection;
    const currentSection: any = sections[currentSectionIdx];
    const questionComponents: any = getSectionComponents(currentSection);

    const areComponentsValid = questionComponents
      ?.map((componentId: string) => {
        const isValid = !formMethods.getFieldState(componentId).invalid;
        return isValid;
      })
      .every((componentIsValid: boolean) => componentIsValid);

    setAllFieldsValid(areComponentsValid);
  };

  const getComponents = () => {
    return normalizedForm?.entities.components;
  };

  const getCommentComponents = () => {
    return normalizedForm.entities.comment_object;
  };

  const getCommentComponent = (componentId: string) => {
    return normalizedForm.entities.comment_object[componentId];
  };

  const getComponent = (componentId: string) => {
    return normalizedForm.entities.components[componentId];
  };

  const getAllComponents = () => {
    const components = Object.values(getComponents());
    const commentComponents =
      (getCommentComponents() && Object.values(getCommentComponents())) || [];
    return [...components, ...commentComponents];
  };

  const getQuestion = (questionId: string) => {
    return normalizedForm.entities.questions[questionId];
  };

  const getCurrentSection = (stepIndex: number) => {
    return stepsState?.[stepIndex]?.currentSection;
  };

  const getSections = () => {
    return normalizedForm?.entities?.sections;
  };

  const buildDraft = () => {
    const draft = [];
    for (const [key, value] of Object.entries(draftSchema)) {
      draft.push({
        id: key,
        value: value,
      });
    }
    return draft;
  };

  const getComponentValue = (value: any, component: any) => {
    if (!value) return;
    const newValue: { value: string; options?: any } = {
      value,
    };

    if (component.type === 'checkbox' || component.type === 'dropdown') {
      newValue.options = component.options.map((option: any) => {
        if (option.label === value || option.label === value.label) {
          return {
            ...option,
            value: true,
          };
        }
        return {
          ...option,
          value: false,
        };
      });
    }

    return newValue;
  };

  const buildFormDataForSave = () => {
    const draft = buildDraft();

    const newCompletedForm = normalizedForm;
    draft.forEach((component) => {
      const isComponent = !!getComponent(component.id);

      if (isComponent) {
        newCompletedForm.entities.components[component.id] = {
          ...newCompletedForm.entities.components[component.id],
          ...getComponentValue(component.value, getComponent(component.id)),
        };
      } else {
        newCompletedForm.entities.comment_object[component.id] = {
          ...newCompletedForm.entities.comment_object[component.id],
          ...getComponentValue(
            component.value,
            getCommentComponent(component.id)
          ),
        };
      }
    });

    const denormalizedForm = denormalize(
      newCompletedForm.result,
      formSchema,
      newCompletedForm.entities
    );

    return denormalizedForm;
  };

  const removeStore = () => {
    resetStore();
    resetDocs();
    localStorage.removeItem('web-form');
  };

  const allowedExtensions = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'video/quicktime',
    'video/mp4',
  ];

  const validateFiles = (fileArray: File[]) => {
    const errors: Error[] = [];

    fileArray.forEach((file: File) => {
      const maxFileSize = 50 * 1024 * 1024; // 50MB in bytes
      if (file.size > maxFileSize) {
        errors.push({
          name: 'File too large',
          message: `${file.name} ${t(
            'inspections-page.file-too-large.trailing'
          )}`,
        });
      }

      if (!allowedExtensions.includes(file.type)) {
        errors.push({
          name: 'Incorrect file format',
          message: `${file.name} ${t(
            'inspections-page.incorrect-format-upload.trailing'
          )} (${file.type.length > 0 ? file.type : 'No extension'}).`,
        });
      }
    });

    if (errors.length > 0) {
      return {
        valid: false,
        errors,
      }; // Return errors if any
    }

    return {
      valid: true,
      errors: null,
    }; // Return null if no errors
  };

  return {
    useGoBack,
    useIsGoBackDisabled,
    useHandleContinue,
    getComponent,
    getComponents,
    getQuestion,
    getCurrentSection,
    getSections,
    getCommentComponents,
    getCommentComponent,
    validateFields,
    allFieldsValid,
    getAllComponents,
    buildFormDataForSave,
    removeStore,
    validateFiles,
  };
};

export default useWebInspection;
