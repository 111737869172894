import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import { useCreateInspection } from '@/queries/useCreateInspection';
import useDeleteInspection from '@/queries/useDeleteInspection';
import { useInspectionsStore } from '@/stores/inspections';
import { useModalsStorage } from '@/stores/modals';

import { ActionModals } from '@/types';

const useInspectionsModals = () => {
  const { deleteInspection, isDeletingInspection } = useDeleteInspection();
  const { createInspection } = useCreateInspection();
  const { currentInspection, setCurrentInspection } = useInspectionsStore();
  const { setIsModalOpen } = useModalsStorage();
  const { t } = useTranslation('common');
  const { query } = useRouter();

  const modals: ActionModals = {
    DELETE_INSPECTION: {
      isSubmitting: isDeletingInspection,
      children: (
        <CommonModalLabel
          redText={t('delete_unarchive_inspection_confirm_red_text')}
          trailingText={t('delete_unarchive_inspection_confirm_after')}
        />
      ),
      onConfirm: () => {
        if (currentInspection) {
          deleteInspection(currentInspection.id);
        }
      },
      onClose: () => {
        setIsModalOpen(false);
        setCurrentInspection();
      },
    },
    NO_INTERNET_CONNECTION: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>
            {t('create-inspection-no-network-red')}
          </label>
          <label>{t('create-inspection-no-network')}</label>
        </div>
      ),
    },
    CREATE_INSPECTION: {
      children: (
        <div className='flex flex-col text-center'>
          <label>{t('create_inspection')}</label>
        </div>
      ),
      onConfirm: () => {
        createInspection({
          task_id: query.workflowId,
        });
      },
    },
    INSPECTION_ALREADY_SUBMITTED: {
      children: (
        <div className='flex flex-col text-center'>
          <label>{t('inspection-already-submitted')}</label>
        </div>
      ),
      closeText: 'Ok',
      onClose: () => {
        setIsModalOpen(false);
      },
    },
  };
  return {
    modals,
  };
};

export default useInspectionsModals;
