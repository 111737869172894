import create, { State as ZustandState } from 'zustand';

import { WorkflowInterface } from '@/types';

interface WorkflowStorageStateInterface extends ZustandState {
  workflows: WorkflowInterface[];
  currentWorkflow?: WorkflowInterface;
  isSubmitting: boolean;
  isRefetching: boolean;
  refetch?: () => void;
  setRefetch: (refetch?: () => void) => void;
  setWorkflows: (workflows: WorkflowInterface[]) => void;
  setCurrentWorkflow: (currentWorkflow?: WorkflowInterface) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setIsRefetching: (isRefetching: boolean) => void;
}

export const useWorkflowsStorage = create<WorkflowStorageStateInterface>(
  (set) => ({
    workflows: [],
    currentWorkflow: undefined,
    isSubmitting: false,
    refetch: undefined,
    isRefetching: false,
    setRefetch: (refetch) => set({ refetch }),
    setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
    setWorkflows: (workflows) => set({ workflows }),
    setCurrentWorkflow: (currentWorkflow) => set({ currentWorkflow }),
    setIsRefetching: (isRefetching) => set({ isRefetching }),
  })
);
