/* eslint-disable @typescript-eslint/no-explicit-any */
import { clsx } from 'clsx';

const InkanLogo = ({ color, className, variant, ...rest }: any) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='_02'
      data-name='02'
      viewBox='0 0 214.8 49.29'
      className={clsx(color, className)}
      {...rest}
    >
      <g id='Logo'>
        <g id='Inkan'>
          <path
            className='fill-current'
            d='M73.93,11h0a3.19,3.19,0,0,0-3.25,3.13V39.52a3.25,3.25,0,0,0,6.5,0V14.13A3.19,3.19,0,0,0,73.93,11Z'
          />
          <path
            className='fill-current'
            d='M73.93.54A3.86,3.86,0,0,0,70,4.33a3.93,3.93,0,0,0,7.86,0A3.86,3.86,0,0,0,73.93.54Z'
          />
          <path
            className='fill-current'
            d='M133.22,27.38l-.23-.15a.8.8,0,0,1-.39-.69.83.83,0,0,1,.41-.7l.24-.14,11.21-9.41a3.06,3.06,0,0,0,.28-4.42h0a3.35,3.35,0,0,0-4.59-.27l-10.81,9.08a7.05,7.05,0,0,0-3.24,5.86,7,7,0,0,0,3.09,5.77l11,9.66a3.34,3.34,0,0,0,4.59-.18h0a3.05,3.05,0,0,0-.19-4.42Z'
          />
          <path
            className='fill-current'
            d='M118.93,11h0a3.19,3.19,0,0,0-3.24,3.13V39.53a3.25,3.25,0,0,0,6.49,0V14.14A3.19,3.19,0,0,0,118.93,11Z'
          />
          <path
            className='fill-current'
            d='M199.94,10.66a14.24,14.24,0,0,0-13.75,14.11V39.52a3.25,3.25,0,0,0,6.49,0V24.43a7.81,7.81,0,0,1,15.62.22V39.52a3.19,3.19,0,0,0,3.25,3.12h0a3.19,3.19,0,0,0,3.25-3.12V24.43C214.8,16.66,208.08,10.37,199.94,10.66Z'
          />
          <path
            className='fill-current'
            d='M96.32,10.66A14.24,14.24,0,0,0,82.57,24.77V39.52a3.19,3.19,0,0,0,3.25,3.12h0a3.18,3.18,0,0,0,3.24-3.12V24.43a7.81,7.81,0,0,1,15.62.22V39.52a3.25,3.25,0,0,0,6.5,0V24.43C111.18,16.66,104.46,10.37,96.32,10.66Z'
          />
          <path
            className='fill-current'
            d='M164,10.62a16.14,16.14,0,0,0-16.33,16.3A16.13,16.13,0,0,0,164,43.21a17.77,17.77,0,0,0,2.65-.2,3.11,3.11,0,0,0,2.41-4.34,3.31,3.31,0,0,0-3.52-1.82A11.17,11.17,0,0,1,164,37a9.72,9.72,0,0,1-9.83-10,9.83,9.83,0,1,1,19.66,0h0v12.6a3.19,3.19,0,0,0,3.25,3.13h0a3.19,3.19,0,0,0,3.25-3.13V26.65h0A16.14,16.14,0,0,0,164,10.62Z'
          />
        </g>
        <g id='Logo-2' data-name='Logo'>
          <ellipse
            className={clsx(
              variant === 'inverted' ? 'fill-white' : 'fill-current'
            )}
            cx='25.59'
            cy='24.65'
            rx='25.59'
            ry='24.65'
          />
          <path
            className={clsx(
              variant === 'inverted' ? 'fill-primary-500' : 'fill-white'
            )}
            d='M34.84,31.2a1.9,1.9,0,0,0,2,1.85,1.93,1.93,0,0,0,1.87-1.92V19.56a4.19,4.19,0,0,0-4.27-4.11h-6.6a.3.3,0,0,1-.31-.3V11.44a1.93,1.93,0,0,0-1.86-1.93,1.89,1.89,0,0,0-2,1.85v3.79a.3.3,0,0,1-.31.3H16.7a4.19,4.19,0,0,0-4.27,4.11V31.13a1.93,1.93,0,0,0,1.86,1.92,1.89,1.89,0,0,0,2-1.85V28.94a.32.32,0,0,1,.58-.16A2.23,2.23,0,0,0,18.57,30h5.1v6.32a1.93,1.93,0,0,0,1.86,1.93,1.89,1.89,0,0,0,2-1.85V30h5a2.24,2.24,0,0,0,1.72-1.21.31.31,0,0,1,.57.16ZM23.36,26.29H16.58a.31.31,0,0,1-.31-.3V19.56a.42.42,0,0,1,.43-.41h4.82A2.33,2.33,0,0,0,23.1,18a.31.31,0,0,1,.57.16V26A.31.31,0,0,1,23.36,26.29Zm4.15-8.18a.31.31,0,0,1,.57-.16,2.37,2.37,0,0,0,1.59,1.2h4.75a.41.41,0,0,1,.42.41V26a.3.3,0,0,1-.31.3H27.82a.31.31,0,0,1-.31-.3Z'
          />
        </g>
      </g>
    </svg>
  );
};

export default InkanLogo;
