/* eslint-disable react-hooks/exhaustive-deps */
import { Theme } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { useTheme } from 'next-themes';
import NextNprogress from 'nextjs-progressbar';
import { ReactNode, useEffect } from 'react';

import useUser from '@/queries/useUser';

type ThemeKey = 'default' | 'inti';

const MUI_THEMES: Record<ThemeKey, Theme> = {
  default: createTheme({
    palette: {
      primary: {
        main: '#FE4545',
      },
    },
  }),
  inti: createTheme({
    palette: {
      primary: {
        main: '#3372db',
      },
    },
  }),
};

const InkanThemeProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    switch (user?.company.layout.slug) {
      case 'inti':
        setTheme('inti');
        break;
      default:
        setTheme('default');
        break;
    }
  }, [setTheme, user]);

  return (
    <>
      {theme && (
        <MuiThemeProvider theme={MUI_THEMES[theme as ThemeKey]}>
          <NextNprogress
            color={MUI_THEMES[theme as ThemeKey].palette.primary.main}
          />
          {children}
        </MuiThemeProvider>
      )}
    </>
  );
};

export default InkanThemeProvider;
