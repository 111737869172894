import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import useWebInspection from '@/hooks/useWebInspection';

import { ERROR_MESSAGES } from '@/constants/Errors';
import useCreateReport from '@/queries/useCreateReport';
import useSaveWebInspection from '@/queries/useSaveWebInspection';
import { useErrorStore } from '@/stores/error';
import { useModalsStorage } from '@/stores/modals';
import { useWebInspectionStorage } from '@/stores/webInspection';
import { useWebInspectionDocs } from '@/stores/webInspectionDocs';
import { buildFormPayload } from '@/utils/webForm';

// TODO: Stop navigation should be fixed and reenabled
import { ActionModals } from '@/types';

const useWebInspectionModals = () => {
  const { t } = useTranslation('common');
  const { createReport, isSavingReport } = useCreateReport();
  const {
    push,
    back,
    query: { folderId, workflowId, inspectionId },
  } = useRouter();
  const { currentStep, resetStore } = useWebInspectionStorage();
  const { saveWebInspection, isSavingWebInspection } = useSaveWebInspection();
  const { buildFormDataForSave, removeStore } = useWebInspection(currentStep);
  const { setIsModalOpen, onModalConfirm } = useModalsStorage();
  const { isRunningQueue } = useWebInspectionDocs();
  const { errors } = useErrorStore();

  const handleSaveInspection = () => {
    const formDataPayload = buildFormDataForSave();
    const formPayload = buildFormPayload(
      formDataPayload,
      parseInt(inspectionId as string)
    );

    saveWebInspection({
      inspectionId,
      data: formPayload,
    });
  };

  const deleteCacheAndNavigateBack = () => {
    location.reload();
    resetStore();
    setIsModalOpen(false);
  };

  const goBack = () => {
    removeStore();
    back();
    setIsModalOpen(false);
  };

  const modals: ActionModals = {
    CERTIFICATION_ERROR: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>
            {t('web-inspection-page.modals.certification-error.red-text')}
          </label>
          <label>
            {t('web-inspection-page.modals.certification-error.ending-text')}
          </label>
        </div>
      ),
      closeText: 'OK',
    },
    NO_CONNECTION: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>
            {t('web-inspection-page.modals.no-connection.red-text')}
          </label>
          <label>
            {t('web-inspection-page.modals.no-connection.ending-text')}
          </label>
        </div>
      ),
      closeText: 'OK',
    },
    GO_BACK: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>{t('go-back-red')}</label>
          <label>{t('go-back-trailing')}</label>
        </div>
      ),
      closeText: 'Cancel',
      onConfirm: goBack,
    },
    CREATE_INSPECTION_REPORT: {
      children: (
        <div className='flex flex-col text-pretty text-center'>
          <label>{t('create_report')}</label>
        </div>
      ),
      onConfirm: () => {
        createReport(parseInt(inspectionId as string));
      },
      isSubmitting: isSavingReport,
    },
    SAVE_ERROR: {
      children: (
        <div className='flex flex-col text-center'>
          <label>{t('inspection-save-error')}</label>
        </div>
      ),
      closeText: 'Cancel',
      confirmText: 'Continue',
    },
    FORM_ALREADY_SUBMITTED: {
      children: (
        <div className='flex flex-col text-center'>
          <label>
            <span>
              {t(ERROR_MESSAGES.inspection_already_submitted.description)}
            </span>
          </label>
        </div>
      ),
      onClose: () => {
        push(`/dashboard/${folderId}/${workflowId}/${inspectionId}`);
        setIsModalOpen(false);
      },
    },
    INSPECTION_SUBMIT_CONFIRM: {
      children: (
        <div className='flex flex-col text-center'>
          <label>
            <span>{t('inspections-page.submit-confirm')}</span>
          </label>
        </div>
      ),
      isSubmitting: isRunningQueue,
      onConfirm: onModalConfirm ? onModalConfirm : undefined,
    },
    SAVE_CONFIRM: {
      children: (
        <div className='flex flex-col text-center'>
          <label>
            <span>{t('inspections-page.save-inspection-confirm.start')}</span>
            <span className='text-inkanTextRed'>
              {t('inspections-page.save-inspection-confirm.red-text')}
            </span>
            <span>{t('inspections-page.save-inspection-confirm.ending')}</span>
          </label>
        </div>
      ),
      onConfirm: () => {
        handleSaveInspection();
      },
      closeText: 'Cancel',
      confirmText: 'Continue',
      isSubmitting: isSavingWebInspection,
    },
    DISCARD: {
      children: (
        <div className='flex flex-col text-center'>
          <label>
            {t('discard-inspection.start')}
            <span className='text-inkanTextRed'>
              {t('discard-inspection.red')}
            </span>
            {t('discard-inspection.trailing')}
          </label>
        </div>
      ),
      onConfirm: deleteCacheAndNavigateBack,
      onClose: () => {
        setIsModalOpen(false);
      },
    },
    UPLOAD_ERROR: {
      children: (
        <div className='flex flex-col text-center'>
          <label>{t('inspections-page.upload-error-title')}</label>
          <ul>
            {errors?.map((error) => (
              <li>{error.message}</li>
            ))}
          </ul>
        </div>
      ),
      onClose: () => {
        setIsModalOpen(false);
      },
    },
  };
  return {
    modals,
  };
};

export default useWebInspectionModals;
