module.exports = {
  locales: ['en', 'es', 'pt'],
  defaultLocale: 'en',
  pages: {
    '*': [
      'common',
      'certifications',
      'pricing',
      'checkout',
      'plans',
      'verifier',
      'register',
      'contact',
      'cookies',
    ],
  },
};
