import clsx from 'clsx';
import { useTheme } from 'next-themes';
import React from 'react';

import InkanLogo from './Inkan';
import IntiLogo from './INTI';

export interface LogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'default' | 'inverted';
  className?: string;
  filled?: boolean;
}

export default function Logo({
  variant = 'default',
  className,
  filled,
}: LogoProps) {
  const { theme } = useTheme();
  const color = variant === 'inverted' ? 'text-white' : 'text-red-500';
  return (
    <>
      {theme === 'default' && (
        <InkanLogo color={color} variant={variant} className={className} />
      )}
      {theme === 'inti' && (
        <IntiLogo
          className={clsx(className, 'mt-2 !h-10 !w-full')}
          filled={filled}
        />
      )}
    </>
  );
}
